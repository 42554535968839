import React from "react";
import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { formatCurrencyForTenant } from "../../utils/currencyUtils";
import { AdSpacePriceConfigTranslations } from "../../utils/spots";
import CheckBox from "../custom-checkbox/custom-checkbox-alternative-2";
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  cardBase: {
    display: "flex",
    maxHeight: "230px",
    border: ({ isSelected }) => (isSelected ? `1px solid ${th.palette.primary.main}` : th.borders[5]),
  },
  card: {
    flexDirection: "column",
    width: "260px",
    height: "230px",
    [th.breakpoints.down("sm")]: {
      flexDirection: "row",
      height: "80px",
      width: "100%",
    },
  },
  cardAdsSpacesModal: {
    flexDirection: "row",
    maxWidth: "348px",
    width: "348px",
    height: "81px",
  },
  cardDesc: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: ({ isDisabled }) => (isDisabled ? theme.palette.lightGray : theme.palette.white),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignItems: "flex-start",
    cursor: ({ isDisabled }) => (isDisabled ? "initial" : "pointer"),
    width: "100%",
  },
  titleText: {
    fontSize: "13px",
    fontWeight: 700,
    display: "inline-block",
    textOverflow: "ellipsis",
    maxHeight: 40,
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "185px",
  },
  imageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "100%",
    padding: "0",
    width: "100%",
    transform: "translate(-0.5px, 0.25px)",
    transition: "all 0.3s",
    "&:hover > div > img": {
      filter: ({ onClickImage }) => (onClickImage ? "brightness(70%)" : "brightness(100%)"),
    },
  },
  visibilityIconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "120px",
    height: "79px",
    cursor: "pointer",
    "&:hover > svg": {
      opacity: 1,
    },
  },
  visibilityIcon: {
    position: "absolute",
    width: "48px",
    height: "48px",
    color: theme.palette.white,
    opacity: 0,
    transition: "all 0.3s",
    filter: "brightness(100%)",
    cursor: "pointer",
  },
  disabledText: {
    color: theme.palette.grayC4,
  },
  text: {
    fontSize: 12,
  },
}));

function AdSpaceCard({
  images,
  title,
  price,
  priceConfig,
  onClickImage,
  onClickCard,
  isSelected,
  isDisabled,
  isAdspacesModal = false,
}) {
  const classes = useStyles({ ...theme, isSelected, isDisabled, onClickImage });
  const { i18n } = useLingui();

  const handleClickCard = () => {
    if (!isDisabled) {
      onClickCard();
    }
  };

  return (
    <Box className={`${classes.cardBase} ${!isAdspacesModal ? classes.card : classes.cardAdsSpacesModal}`}>
      <Box className={classes.imageBox}>
        <Image
          disableSpinner
          color={theme.palette.darkGray}
          src={images}
          style={{
            height: "100%",
            padding: "0",
            width: "100%",
            cursor: "pointer",
          }}
          imageStyle={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
        {onClickImage && (
          <Box onClick={onClickImage} className={classes.visibilityIconBox}>
            <VisibilityIcon className={classes.visibilityIcon} />
          </Box>
        )}
      </Box>
      <Box className={classes.cardDesc} onClick={handleClickCard} textAlign="left">
        <Box color="text.primary">
          <Typography variant="body1" className={`${classes.titleText} ${isDisabled ? classes.disabledText : ""}`}>
            {title}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center" color="text.primary">
              <Box display="flex" flexDirection="row" alignItems="center" style={{ flexShrink: 0 }}>
                <Typography variant="subtitle1" color="primary" className={classes.text}>
                  <Trans>Desde</Trans> {formatCurrencyForTenant(price)}/
                  {i18n._(AdSpacePriceConfigTranslations[priceConfig])}
                </Typography>
              </Box>

              {!isDisabled && (
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <CheckBox onClick={onClickCard} checked={isSelected} style={{ paddingRight: "0px" }} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AdSpaceCard;
